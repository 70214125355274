<template>
  <v-dialog :value="dialog" persistent max-width="60%" max-height="60%">
    <v-card>
      <v-card-title class="text-h5"> Memory Module </v-card-title>

      <span style="margin-left: 25px">Format: { "memory1": value, "memory2": value }</span>
      <div class="px-5 my-5">
        <JsonEditorVue :mode="'text'" v-model="edit.memory"/>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="updateMemory"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import JsonEditorVue from "json-editor-vue";

const {
  mapActions: AIChatActions,
  mapGetters: AIChatGetters,
} = createNamespacedHelpers('AIChat');

export default {
  name: 'MemoryModal',
  components: {JsonEditorVue},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...AIChatGetters({
      edit: 'GET_EDIT_SESSION',
    })
  },
  methods: {
    ...AIChatActions(['updateSession']),
    async updateMemory() {
      await this.updateSession({ memory: this.edit?.memory, sessionId: this.edit?.id })
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './memory-modal';
</style>
